var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.element.id,staticClass:"element-preview",class:{
    'element-preview--active': _vm.isSelected,
    'element-preview--parent-of-selected': _vm.isParentOfSelectedElement,
    'element-preview--in-error': _vm.inError,
    'element-preview--first-element': _vm.isFirstElement,
    'element-preview--not-visible':
      !_vm.isVisible && !_vm.isSelected && !_vm.isParentOfSelectedElement,
  },on:{"click":_vm.onSelect}},[(_vm.isSelected)?_c('div',{staticClass:"element-preview__name"},[_vm._v("\n    "+_vm._s(_vm.elementType.name)+"\n    "),(!_vm.isVisible)?_c('i',{staticClass:"iconoir-eye-off"}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.canCreate)?_c('InsertElementButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelected),expression:"isSelected"}],staticClass:"element-preview__insert element-preview__insert--top",on:{"click":function($event){return _vm.showAddElementModal(_vm.PLACEMENTS.BEFORE)}}}):_vm._e(),_vm._v(" "),(_vm.isSelected && _vm.canUpdate)?_c('ElementMenu',{attrs:{"placements":_vm.placements,"placements-disabled":_vm.placementsDisabled,"is-duplicating":_vm.isDuplicating,"has-parent":!!_vm.parentElement},on:{"delete":_vm.deleteElement,"move":function($event){return _vm.$emit('move', $event)},"duplicate":_vm.duplicateElement,"select-parent":function($event){return _vm.selectParentElement()}}}):_vm._e(),_vm._v(" "),_c('PageElement',{staticClass:"element--read-only",attrs:{"element":_vm.element,"mode":_vm.mode,"application-context-additions":_vm.applicationContextAdditions}}),_vm._v(" "),(_vm.canCreate)?_c('InsertElementButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelected),expression:"isSelected"}],staticClass:"element-preview__insert element-preview__insert--bottom",on:{"click":function($event){return _vm.showAddElementModal(_vm.PLACEMENTS.AFTER)}}}):_vm._e(),_vm._v(" "),(_vm.canCreate)?_c('AddElementModal',{ref:"addElementModal",attrs:{"element-types-allowed":_vm.elementTypesAllowed,"page":_vm.page}}):_vm._e(),_vm._v(" "),(_vm.inError)?_c('i',{staticClass:"element-preview__error-icon iconoir-warning-circle"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }