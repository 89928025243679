var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar",class:{ 'sidebar--collapsed': _vm.collapsed }},[_vm._l((_vm.impersonateComponent),function(component,index){return _c(component,{key:index,tag:"component"})}),_vm._v(" "),(_vm.showAdmin)?[_c('div',{staticClass:"sidebar__head"},[_c('a',{staticClass:"sidebar__back",attrs:{"href":"#"},on:{"click":function($event){return _vm.setShowAdmin(false)}}},[_c('i',{staticClass:"sidebar__back-icon iconoir-nav-arrow-left"})]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],staticClass:"sidebar__title"},[_vm._v("\n        "+_vm._s(_vm.$t('sidebar.adminSettings'))+"\n      ")])]),_vm._v(" "),_c('SidebarAdmin',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}]})]:_vm._e(),_vm._v(" "),(!_vm.showAdmin)?[_c('a',{ref:"workspaceContextAnchor",staticClass:"sidebar__workspaces-selector",on:{"click":function($event){return _vm.$refs.workspacesContext.toggle(
          _vm.$refs.workspaceContextAnchor,
          'bottom',
          'left',
          8,
          16
        )}}},[_c('Avatar',{attrs:{"initials":_vm._f("nameAbbreviation")(_vm.selectedWorkspace.name || _vm.name)}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],staticClass:"sidebar__workspaces-selector-selected-workspace"},[_vm._v(_vm._s(_vm.selectedWorkspace.name || _vm.name))]),_vm._v(" "),(_vm.unreadNotificationsInOtherWorkspaces)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],staticClass:"sidebar__unread-notifications-icon"}):_vm._e(),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],staticClass:"sidebar__workspaces-selector-icon baserow-icon-up-down-arrows"})],1),_vm._v(" "),_c('SidebarUserContext',{ref:"workspacesContext",attrs:{"workspaces":_vm.workspaces,"selected-workspace":_vm.selectedWorkspace},on:{"toggle-admin":function($event){return _vm.setShowAdmin($event)}}}),_vm._v(" "),(_vm.hasSelectedWorkspace)?_c('SidebarMenu',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],attrs:{"selected-workspace":_vm.selectedWorkspace}}):_vm._e(),_vm._v(" "),(_vm.hasSelectedWorkspace)?_c('SidebarWithWorkspace',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],attrs:{"applications":_vm.applications,"selected-workspace":_vm.selectedWorkspace}}):_vm._e(),_vm._v(" "),(!_vm.hasSelectedWorkspace)?_c('SidebarWithoutWorkspace',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],attrs:{"workspaces":_vm.workspaces}}):_vm._e()]:_vm._e(),_vm._v(" "),_c('SidebarFoot')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }